/* global cookie_consent_accept_url */
$(document).ready(function () {
    'use strict';

    $('#fairlane-cookie-consent button').click(function () {
        $.ajax({
            async: true,
            type: 'POST',
            url: cookie_consent_accept_url,
            data: JSON.stringify({ 'cookie-consent': 'ok' }),
            success(data) {
                $('#fairlane-cookie-consent').fadeOut('fast');
            },
            contentType: 'application/json',
            dataType: 'json'
        });
    });
});
